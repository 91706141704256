import React from 'react';

import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import HeaderBackBtn from '@components/HeaderBackBtn/HeaderBackBtn';
import IconListChecklist from '@components/IconList/IconListChecklist';

// Styles
import './_Header.scss';

Header.defautlProps = {
    title: 'My Defautl Title',
    text: 'My default Text',
    toCredit: false,
    align: 'left',
    theme: 'dark',
    wideVisual: false,
};

function Header(props) {
    const mod_class = ['Header', props.size ? 'size-' + props.size : ' '].join(
        ' '
    );

    const vis_class = [
        'Header--item Header--visual',
        props.wideVisual ? 'is-wide' : ' ',
    ].join(' ');

    function render_visual() {
        return <div className={vis_class}>{props.image}</div>;
    }

    return (
        <header className={mod_class}>
            {props.imageMobile && (
                <div className="Header---mobile_visual">
                    {props.imageMobile}
                </div>
            )}
            <Section
                theme={props.theme}
                container={props.container}
                spacing={props.spacing}
            >
                <div className="Header--inner">
                    <div className="Header--item Header--content">
                        {props.showBackBtn ? <HeaderBackBtn /> : ''}
                        <Title
                            tag="h1"
                            size="xxl"
                            font="alt"
                            align={props.align}
                            theme={props.theme === 'dark' ? 'white' : 'dark'}
                        >
                            {props.title}
                        </Title>

                        {props.text ? (
                            <Text
                                align={props.align}
                                theme={props.theme === 'dark' ? 'white' : ''}
                            >
                                {props.text}
                            </Text>
                        ) : (
                            ''
                        )}
                        {props.list && <IconListChecklist list={props.list} />}

                        {props.subcontent ? props.subcontent : ''}
                        {props.btnPrimary ? (
                            <Divider spacing="xs" invisible />
                        ) : (
                            ''
                        )}
                        <div className="Header--ctas g_center_mobile">
                            {props.btnPrimary}
                            {/* Render Secondary Button */}
                            {props.btnSecondary !== undefined
                                ? props.btnSecondary
                                : ''}
                        </div>
                    </div>

                    {props.image ? render_visual() : ''}
                </div>
            </Section>
        </header>
    );
}

Header.defaultProps = {
    siteTitle: ``,
    container: 'large',
};

export default Header;
