import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Link from '@components/Link';

import React, { useState } from 'react';
import { BsArrowLeftShort, BsArrowRepeat } from 'react-icons/bs';
import Button from '@atoms/Button/Button';
import Logo from '@atoms/Logo/Logo';
import './_TopBar.scss';

TopBar.defaultProps = {
    theme: 'light', // dark
    btnLabel: 'Jetzt Therapeut:in finden',
};

function TopBar(props) {
    const THRESHOLD = 50;
    const [headerIsFixed, setHeaderIsFixed] = useState(false);

    let mod_class = [
        'TopBar',
        headerIsFixed === true ? 'is-fixed' : '',
        props.theme !== undefined ? 'theme-' + props.theme : '',
        props.variant !== undefined ? 'variant-' + props.variant : '',
    ];
    mod_class = mod_class.join(' ');

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (window.innerWidth > 750) {
                if (Math.abs(currPos.y) > THRESHOLD) {
                    setHeaderIsFixed(true);
                } else {
                    setHeaderIsFixed(false);
                }
            }
        },
        [headerIsFixed],
        false,
        false,
        30
    );

    function handleGoBack() {
        if (window) {
            window.history.back();
        }
    }

    return (
        <>
            <div className={mod_class}>
                <div className="TopBar--inner">
                    <div className="TopBar--left">
                        <div className="g_invisible">back</div>
                        {props.to || props.goBack ? (
                            <div className="TopBar--icon">
                                {props.goBack ? (
                                    <Button
                                        variant="naked"
                                        size="l"
                                        label={<BsArrowLeftShort />}
                                        onClick={() => handleGoBack()}
                                    />
                                ) : props.label ? (
                                    <Link to={props.to} replace={props.replace}>
                                        <Button
                                            variant="naked"
                                            size="m"
                                            label={props.label}
                                            icon={<BsArrowRepeat />}
                                        />
                                    </Link>
                                ) : (
                                    <Link to={props.to} replace={props.replace}>
                                        <Button
                                            variant="naked"
                                            size="l"
                                            label={<BsArrowLeftShort />}
                                        />
                                    </Link>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="TopBar--middle">
                        <div className="TopBar--item TopBar--Logo g_center">
                            <Logo noLink={props.noLink} />
                        </div>
                    </div>
                    <div className="TopBar--right"></div>
                </div>
            </div>
        </>
    );
}

export default TopBar;
