import React from 'react';
import { useEffect } from 'react';
import { getOriginalLocale } from '@helpers/GlobalHelpers';
import { SUPPORTED_USER_LANG } from '@constants/common';

const withLocale = (Component: any) => (props: any) => {
    const getCorrectUrl = () => {
        const {
            pageContext: { language: gatsbyLang, locale: cmsLang },
            path,
        } = props;

        const [_, urlLocale] = path.split('/');

        if (!SUPPORTED_USER_LANG.includes(urlLocale)) {
            if (cmsLang) {
                return `/${getOriginalLocale(cmsLang)}${path}`;
            }
            const browserLang = window.navigator?.language;
            if (browserLang) {
                const convertedLang = getOriginalLocale(browserLang);

                return `/${
                    SUPPORTED_USER_LANG.includes(convertedLang)
                        ? convertedLang
                        : gatsbyLang
                }${path}`;
            }
        }
    };
    useEffect(() => {
        if (props?.pageContext) {
            const correctUrl = getCorrectUrl();
            if (correctUrl && typeof window !== 'undefined') {
                window.location.href = `${correctUrl}${window.location.search}`;
            }
        }
    }, []);
    return <Component {...props} />;
};

export default withLocale;
