import React from 'react';

import './IconList.scss';
import IconListItem, { IconListItemProps } from './IconListItem';

import { BiCheck } from 'react-icons/bi';

interface Props {
    list: Array<string>;
    size: 's' | 'm';
}

const IconListChecklist: React.FC<Props> = ({ list, size = 'm' }) => {
    let className = ['IconList', 'IconListChecklist']
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    return (
        <ul className={className}>
            {list.map((el, index) => {
                return (
                    <IconListItem
                        isHighlighted={false}
                        icon={<BiCheck fill={'#516253'} size={'22'} />}
                        label={el}
                        size={size}
                        key={index}
                    />
                );
            })}
        </ul>
    );
};

export default IconListChecklist;
