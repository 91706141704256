import React, { useState } from 'react';

import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import Section from '@atoms/Section/Section';

import './IconList.scss';

export interface IconListItemProps {
    isHighlighted?: boolean;
    icon: React.ReactNode;
    label: string;
    size: 's' | 'm';
}

const IconListItem: React.FC<IconListItemProps> = ({
    isHighlighted,
    icon,
    label,
    size = 'm',
}) => {
    let className = ['IconListItem'].join(' ').replace(/\s{2,}/g, ' ');

    return (
        <li className={className}>
            <span className="IconListItem--icon">
                <Text tag="span" size={size}>
                    {icon}
                </Text>
            </span>
            <Text tag="span" size={size}>
                {label}
            </Text>
        </li>
    );
};

export default IconListItem;
