import Link from '@components/Link';
import React from 'react';

import Button from '@atoms/Button/Button';
import './HeaderBackBtn.scss';

import { BsArrowLeftShort } from 'react-icons/bs';

export default function HeaderBackBtn(props) {
    let mod_class = [
        'HeaderBackBtn',
        props.absolute === true ? 'is-absolute' : '',
    ];
    mod_class = mod_class.join(' ');

    function handleClick(e) {
        window.history.back();
    }

    return (
        <div>
            <div className={mod_class}>
                {props.to ? (
                    <Link to={props.to}>
                        <Button
                            variant="primary"
                            theme="ghosted"
                            label={<BsArrowLeftShort />}
                        />
                    </Link>
                ) : (
                    <Button
                        variant="primary"
                        theme="highlighted"
                        label={<BsArrowLeftShort />}
                        onClick={(e) => handleClick(e)}
                    />
                )}
            </div>
        </div>
    );
}
